import React, {FC, useCallback} from "react";
import {Input} from "antd";
import {formatNumber} from "../../../services/numberHelpers";
import classes from './CustomInputNumber.module.scss';

interface CustomInputNumberProps {
    min?: number,
    max?: number,
    value?: number,
    label?: string,
    leftAddon?: any,
    rightAddon?: any,
    onChange?: (value: number) => void
}

const CustomInputNumber: FC <CustomInputNumberProps> =
    ({
         min= 0,
         max = 1000000000,
         value,
         label,
         leftAddon = '',
         rightAddon = '',
         onChange: onChangeCallBack = () => {}
    }
    ) => {
    const onChange = useCallback((e: any) => {
        const { value } = e.target;
        const valueMerger = value.split(/[, ]/).join('')
        const reg = /^-?\d*(\.\d*)?$/;

        if ((!isNaN(valueMerger) && max >= valueMerger && reg.test(valueMerger)) || valueMerger === '' || valueMerger === '-') {
            onChangeCallBack(parseInt(valueMerger));
        }

    }, [max, onChangeCallBack]);

    const onBlur = useCallback((e: { target: { value: any; }; }) => {
        const { value } = e.target;
        if (value < min ) {
            onChangeCallBack(min);
        }
    }, [min, onChangeCallBack]);

    return (
        <div>
            {label && <label className={classes.label}>{label}</label>}
            <Input
                min={min}
                max={max}
                value={formatNumber(value)}
                addonBefore={leftAddon}
                onChange={onChange}
                onBlur={onBlur}
                addonAfter={rightAddon}
                className={classes.input}
            />
        </div>
    )
}

export default CustomInputNumber;
