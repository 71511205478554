import {useCallback} from 'react';
import {GoogleAuthProvider, signInWithPopup} from 'firebase/auth';
import {authFireBase} from '../firebase';
import {getDoc, setDoc} from 'firebase/firestore';
import {generalSettings} from '../consts';
import {useDispatch} from 'react-redux';
import {SET_CHECK_CREATED_USER_DOC} from '../store/actionTypes';
import {baseSymbols} from '../constans/baseSymbols';
import {useFirebase} from "./useFirebase";

const useAuth = () => {
    const googleProvider = useCallback(() => {
        return new GoogleAuthProvider()
    }, []);
    const dispatch = useDispatch();
    const {getUserRef, getSettingsFilterRef, getSymbolsListRef} = useFirebase();
    const logInWidthGoogle = useCallback(async () => {
        dispatch({
            type: SET_CHECK_CREATED_USER_DOC,
            payload: false
        })
        const res = await signInWithPopup(authFireBase, googleProvider());
        const uid = res.user.uid;

        const userRef = getUserRef(uid);
        const settingsFilterRef = getSettingsFilterRef(uid);
        const symbolsListRef = getSymbolsListRef(uid);

        const user = await getDoc(userRef);

        if (!user.data()) {
            try {
                await setDoc(userRef, {
                    user: {
                        uid: res.user.uid,
                        name: res.user.displayName,
                        email: res.user.email,
                        authProvider: "google"
                    }

                });

                await setDoc(settingsFilterRef, {
                    ...generalSettings
                });

                await setDoc(symbolsListRef, {
                    ...baseSymbols
                });
                dispatch({
                    type: SET_CHECK_CREATED_USER_DOC,
                    payload: true
                })
            } catch (e) {
                console.log(e);
            }
        } else {
            dispatch({
                type: SET_CHECK_CREATED_USER_DOC,
                payload: true
            })
        }


    }, [dispatch, googleProvider, getSettingsFilterRef, getUserRef, getSymbolsListRef]);

    return {
        logInWidthGoogle
    }
}

export default useAuth;
