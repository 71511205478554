import {REMOVE_SYMBOL, SET_SYMBOL_LIST} from "./types";

interface IListSymbolAction {
    type: string
    payload: any
}

const defaultState = {

}

export const listSymbolReducer = (state= defaultState, action: IListSymbolAction) => {
    switch (action.type) {
        case SET_SYMBOL_LIST:
            return {
                ...state,
                ...action.payload
            }
        case REMOVE_SYMBOL:
            return {
                ...action.payload
            }
        default:
            return state;
    }
}
