import React, {FC, memo, useEffect, useRef, useState} from 'react';
import {createChart} from "lightweight-charts";
import classes from "./BigChart.module.scss";
import {IRestResponse} from "./BigChart";
import {settingChart, settingSeries} from "./chartSettings";
import {useSelector} from "react-redux";

interface ChartComponentProps {
    dataRest: IRestResponse[],
    dataWs: IRestResponse | undefined,
    height: number
}

const drawLines:any = []

const ChartComponent: FC<ChartComponentProps> = (
    {
        dataRest,
        dataWs,
        height
    }) => {

    const chart = useRef();
    const chartContainerRef = useRef();
    const [candleSeries, setCandleSeries] = useState<any>();

    useEffect(() => {
        // @ts-ignore
        chart.current = createChart(chartContainerRef.current);
        // @ts-ignore
        setCandleSeries(chart.current.addCandlestickSeries(settingSeries))
    }, [height]);



    useEffect(() => {
        if(dataRest.length && candleSeries && height ) {
            // @ts-ignore
            candleSeries.setData(dataRest);
            // @ts-ignore
            chart.current.applyOptions({...settingChart, height: height - 61});

        }
    }, [dataRest, candleSeries, height]);


    useEffect(() => {
        if ( dataWs && Object.keys(dataWs).length && candleSeries) {
            // @ts-ignore
            candleSeries.update(dataWs);

        }

    }, [dataWs, candleSeries]);

    // @ts-ignore
    const linePrice = useSelector(state => state.priceLine);

    useEffect(() => {

    }, [linePrice])

    useEffect(() => {
        if (drawLines.length && candleSeries) {
            drawLines.map((line:any) => {
                candleSeries.removePriceLine(line);
                return [];
            })
        }

        if (Object.values(linePrice).length) {
            Object.values(linePrice).map(line => {
                if (candleSeries) {
                    // @ts-ignore
                    return drawLines.push(candleSeries.createPriceLine({
                        // @ts-ignore
                        price: parseFloat(line.price),
                        // @ts-ignore
                        color: line.color,
                        // @ts-ignore
                        title: `${line.value} ${line.percent}`,
                    }))
                }
                return []
            })
        }


    },[candleSeries, linePrice])

    return (
        <div
            className={classes.chart}
            // @ts-ignore
            ref={chartContainerRef}
        />
    );
};

export default memo(ChartComponent);
