import React, {FC, memo} from 'react';
import {Tag} from "antd";
import classes from "./ModalSymbolSearch.module.scss";

interface ITags {
    symbols: string[]
}

const Tags:FC <ITags> = ({ symbols }) => {
    return <div>{!!symbols.length && symbols.map((el => <Tag key={el} className={classes.tag} color="#2db7f5">{el.toUpperCase()}</Tag>))}</div>;
};

export default memo(Tags);
