import {SET_PRICE_LINE} from "../actionTypes";

const defaultState = {};

// @ts-ignore
export const priceLineReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SET_PRICE_LINE:
            return {
                ...state,
                [action.payload.type]: action.payload
            }
        default:
            return state;
    }
}