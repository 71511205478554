import React, { FC } from "react";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import classes from './QuestionTooltip.module.scss';

interface IQuestionTooltip {
    textTooltip: string
    position: 'left' | 'right',
    children: React.ReactNode
}

const QuestionTooltip:FC <IQuestionTooltip> = (
    {
        textTooltip,
        position,
        children
    }) => {
    return (
        <Tooltip overlayClassName={classes.tooltip} title={textTooltip}>
            <div className={classes.content}>
                {position === 'right' ? <div className={classes.contentLeft}>{children}</div> : ''}
                <QuestionCircleOutlined className={classes.icon}/>
                {position === 'left' ? <div className={classes.contentRight}>{children}</div> : ''}
            </div>
        </Tooltip>
    );
};

export default QuestionTooltip;