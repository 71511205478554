import React from 'react';
import classes from "./UserInformation.module.scss";
import {Avatar, Tag} from "antd";
import {LogoutOutlined, SettingOutlined} from "@ant-design/icons";

// @ts-ignore
const PopoverUserInformation = ({ userData, handlerLogOut }) => {
    return (
        <div className={classes.popoverBody}>
            <header className={classes.popoverHeader}>
                <Avatar className={classes.avatar} style={{ backgroundColor: '#5a9cff', verticalAlign: 'middle' }} size="large">
                    {userData.displayName[0]?.toUpperCase()}
                </Avatar>
                <div className={classes.displayName}>
                    <div>{userData.displayName}<span className={classes.tag}><Tag color="#f50">Free</Tag></span></div>
                    <span className={classes.popoverHeaderAnnotation}>Show profile</span>
                </div>
            </header>
            <ul className={classes.popoverContent}>
                <li>
                    <SettingOutlined style={{fontSize: '17px'}} />
                    <span className={classes.textLi}>Settings</span>
                </li>
            </ul>
            <footer className={classes.popoverFooter}>
                <ul>
                    <li onClick={handlerLogOut}>
                        <LogoutOutlined style={{fontSize: '17px'}} />
                        <span className={classes.textLi}>Log out</span>
                    </li>
                </ul>
            </footer>
        </div>
    );
};

export default PopoverUserInformation;
