import React, {FC, useCallback, useEffect, useState} from 'react';
import cx from "classnames";
import classes from "./OrderCup.module.scss";
import {maxValueFromArray, reduceNumber} from "../../services/numberHelpers";
import * as dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import {useDispatch} from "react-redux";
import {SET_PRICE_LINE} from "../../store/actionTypes";
dayjs.extend(relativeTime)

export enum ISide {
    bid = 'bid',
    ask = 'ask',
}

interface OrderCupItemProps {
    list: (number)[][]
    countMax: number
    variant: ISide
    priceCurrent: number
    linePrices: any
    setLinePrices: any
    type: string
}

const OrderCupItem: FC<OrderCupItemProps> = (
    {
        list,
        countMax,
        variant,
        priceCurrent,
        type
    }) => {

    const [maxValue, setMaxValue] = useState<number>(0)

    const dispatch = useDispatch()

    useEffect(() => {
        setMaxValue(maxValueFromArray(list))
    }, [list])

    const addLinePrice = useCallback((price: any, percent: any, value: any) => {
        dispatch({
            type: SET_PRICE_LINE,
            payload: {
                type: `${type}_${variant}`,
                variant,
                price,
                percent,
                value,
                color: variant === ISide.bid ? '#019a23' : '#de2b2b'
            }
        })
    }, [dispatch, type, variant])

    return (
        <div
            className={cx({[classes.bids]: variant === ISide.bid}, {[classes.asks]: variant === ISide.ask}, classes.cup)}
            style={{height: `calc(50vh-100px)`}}>
            {list.map((item) => {
                const [price, value] = item;

                const priceFloat = price;
                const valueFloat = value
                const priceCurrentFloat = priceCurrent;

                const sum = priceFloat * valueFloat;
                const maxSum = priceFloat * maxValue
                const width = (sum * 100 / countMax);
                const widthStyle = width >= 100 ? 100 : width;
                const percent = priceFloat < priceCurrentFloat ? ((priceCurrentFloat-priceFloat)/priceFloat) * 100 : ((priceFloat-priceCurrentFloat)/priceFloat) * 100

                return (
                    <div>
                        <div
                            onClick={() => addLinePrice(priceFloat.toFixed(4), percent.toFixed(1), reduceNumber((sum), 1))}
                            key={item[0]}
                            className={
                                cx(classes.row, classes.item,
                                    {[classes.green]: variant === ISide.bid},
                                    {[classes.red]: variant === ISide.ask},
                                    {[classes.maxValue]: maxSum === sum})
                            }>
                            <div className={cx(classes.bg) } style={{'width': `${widthStyle}%`}}/>
                            <div className={classes.result}>
                                <span className={classes.price}>{(priceFloat).toFixed(4)}</span>
                                <span title={`${valueFloat}`} className={classes.coin}>{item.length ? reduceNumber((sum), 1) : ''}</span>
                                <span>{percent < 0.1 ? percent.toFixed(2) : percent.toFixed(1)}%</span>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    );
};

export default OrderCupItem;
