import React, {FC, useCallback, useEffect, useState} from 'react';
import {Alert, Button, Form} from 'antd';
import InputBase from '../../../components/inputs/InputBase';
import {createUserWithEmailAndPassword, onAuthStateChanged, updateProfile} from 'firebase/auth';
import {authFireBase, dbFirebase} from '../../../firebase';
import {SET_USER_DATA} from '../../../store/actionTypes';
import {useDispatch, useSelector} from 'react-redux';
import {doc, setDoc} from 'firebase/firestore';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {generalSettings} from '../../../consts';
import {GoogleOutlined} from '@ant-design/icons';
import useAuth from '../../../hooks/useAuth';
import {baseSymbols} from '../../../constans/baseSymbols';

const Register:FC = () => {
    const { t } = useTranslation();

    useEffect(() => {
        document.title = t('register');
    }, [t]);

    const [loadForm, setLoadForm] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // @ts-ignore
    const authorized = useSelector(state => state.user.authorized);
    const { logInWidthGoogle } = useAuth();
    const [errors, setErrors] = useState('');
    const sendForm = useCallback(async (data: any) => {
        setLoadForm(true)
        try {
            const resUser = await createUserWithEmailAndPassword(authFireBase, data.email, data.password);

            try {
                await setDoc(doc(dbFirebase, "users", resUser.user.uid), {
                    user: {
                        uid: resUser.user.uid,
                        name: data.name,
                        email: data.email,
                        authProvider: "EmailAndPassword"
                    }
                });
                await setDoc(doc(dbFirebase, "users", resUser.user.uid, 'general-settings'), {
                    ...generalSettings
                });
                await setDoc(doc(dbFirebase, "users", resUser.user.uid, 'symbols'), {
                    ...baseSymbols
                });
            } catch (e) {
                console.log(e);
            }

            if (authFireBase.currentUser) {
                await updateProfile(authFireBase.currentUser, {displayName: data.name})
                await onAuthStateChanged(authFireBase, (user: any) => {
                    if (user) {
                        dispatch({
                            type: SET_USER_DATA,
                            payload: user
                        });
                    }

                })
                setLoadForm(false);
            }

        }catch (error) {
            setLoadForm(false);
            // @ts-ignore
            const message = `${error.response?.data?.code ?? error?.code ?? error?.response?.obj?.code ?? error?.name ?? 'somethingWentWrong'}`
            setErrors(message)
        }




    }, [setLoadForm, dispatch]);

    useEffect(() => {
        if (authorized) {
            navigate('/');
        }
    }, [navigate, authorized]);

    return (
        <>
            {
                !authorized &&
                <div className="auth">
                    <div>
                        <h1 className="text-center">{t('register')}</h1>
                        <Form.Item>
                            <Button onClick={logInWidthGoogle} size="large" icon={<GoogleOutlined />} block type="primary" ghost>{t('loginWithGoogle')}</Button>
                        </Form.Item>
                        <Form.Item>
                            <div className="or-in-form">
                                {t('or')}
                            </div>
                        </Form.Item>
                        <Form
                            name="normal_registration"
                            onFinish={sendForm}
                            className="auth-form"
                        >
                            {
                                !!errors.length && <Form.Item>
                                    <Alert message={t(`auth.errors.${errors}`)} type="error" showIcon />
                                </Form.Item>
                            }
                            <Form.Item
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: t('auth.errors.Please input your name!'),
                                    },
                                ]}
                            >
                                <InputBase size="large" placeholder={t('name')} />
                            </Form.Item>
                            <Form.Item
                                name="email"
                                rules={[
                                    {
                                        type: 'email',
                                        message: t('auth.errors.The input is not valid E-mail!'),
                                    },
                                    {
                                        required: true,
                                        message: t('auth.errors.Please input your E-mail!'),
                                    },
                                ]}
                            >
                                <InputBase size="large" placeholder={t('email')} />
                            </Form.Item>

                            <Form.Item
                                name="password"
                                rules={[{ required: true, message: t('auth.errors.Please input your Password!') }]}
                            >
                                <InputBase size="large" type="password" placeholder={t('password')} />
                            </Form.Item>
                            <Form.Item>
                                <Button disabled={loadForm} size="large" block type="primary" htmlType="submit" className="login-form-button">
                                    {t('register')}
                                </Button>
                            </Form.Item>

                        </Form>
                    </div>
                </div>
            }
        </>
    );
};

export default Register;
