export const baseSymbols = {
    btcusdt: {
        index: 1,
        symbol: 'btcusdt',
        coin: 'btc',
        to: 'usdt',
        source: 'binance',
        baseSettings: true
    },
    ethusdt: {
        index: 2,
        symbol: 'ethusdt',
        coin: 'eth',
        to: 'usdt',
        source: 'binance',
        baseSettings: true
    },
    bnbusdt: {
        index: 3,
        symbol: 'bnbusdt',
        coin: 'bnb',
        to: 'usdt',
        baseSettings: true
    },
    xrpusdt: {
        index: 4,
        symbol: 'xrpusdt',
        coin: 'xrp',
        to: 'usdt',
        source: 'binance',
        baseSettings: true
    },
    adausdt: {
        index: 5,
        symbol: 'adausdt',
        coin: 'ada',
        to: 'usdt',
        source: 'binance',
        baseSettings: true
    },
    solusdt: {
        index: 6,
        symbol: 'solusdt',
        coin: 'sol',
        to: 'usdt',
        source: 'binance',
        baseSettings: true
    },
    dogeusdt: {
        index: 7,
        symbol: 'dogeusdt',
        coin: 'doge',
        to: 'usdt',
        source: 'binance',
        baseSettings: true
    },
    dotusdt: {
        index: 8,
        symbol: 'dotusdt',
        coin: 'dot',
        to: 'usdt',
        source: 'binance',
        baseSettings: true
    },
    daiusdt: {
        index: 9,
        symbol: 'daiusdt',
        coin: 'dai',
        to: 'usdt',
        source: 'binance',
        baseSettings: true
    },
    maticusdt: {
        index: 10,
        symbol: 'maticusdt',
        coin: 'matic',
        to: 'usdt',
        source: 'binance',
        baseSettings: true
    },
    shibusdt: {
        index: 11,
        symbol: 'shibusdt',
        coin: 'shib',
        to: 'usdt',
        source: 'binance',
        baseSettings: true
    },
    trxusdt: {
        index: 12,
        symbol: 'trxusdt',
        coin: 'trx',
        to: 'usdt',
        source: 'binance',
        baseSettings: true
    },
    avaxusdt: {
        index: 13,
        symbol: 'avaxusdt',
        coin: 'avax',
        to: 'usdt',
        source: 'binance',
        baseSettings: true
    },
    leousdt: {
        index: 14,
        symbol: 'leousdt',
        coin: 'leo',
        to: 'usdt',
        source: 'binance',
        baseSettings: true
    },
    wbtcusdt: {
        index: 15,
        symbol: 'wbtcusdt',
        coin: 'wbtc',
        to: 'usdt',
        source: 'binance',
        baseSettings: true
    },
    uniusdt: {
        index: 16,
        symbol: 'uniusdt',
        coin: 'uni',
        to: 'usdt',
        source: 'binance',
        baseSettings: true
    },
    etcusdt: {
        index: 17,
        symbol: 'etcusdt',
        coin: 'etc',
        to: 'usdt',
        source: 'binance',
        baseSettings: true
    },
    ltcusdt: {
        index: 18,
        symbol: 'ltcusdt',
        coin: 'ltc',
        to: 'usdt',
        source: 'binance',
        baseSettings: true
    },
    fttusdt: {
        index: 19,
        symbol: 'fttusdt',
        coin: 'ftt',
        to: 'usdt',
        source: 'binance',
        baseSettings: true
    },
    atomusdt:{
        index: 20,
        symbol: 'atomusdt',
        coin: 'atom',
        to: 'usdt',
        source: 'binance',
        baseSettings: true
    },
    linkusdt: {
        index: 21,
        symbol: 'linkusdt',
        coin: 'link',
        to: 'usdt',
        source: 'binance',
        baseSettings: true
    },
    nearusdt: {
        index: 22,
        symbol: 'nearusdt',
        coin: 'near',
        to: 'usdt',
        source: 'binance',
        baseSettings: true
    },
    crousdt: {
        index: 23,
        symbol: 'crousdt',
        coin: 'cro',
        to: 'usdt',
        source: 'binance',
        baseSettings: true
    },
}
