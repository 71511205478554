import React from "react";
import classes from "./TableHeader.module.scss";
import { useTranslation } from "react-i18next";
import QuestionTooltip from "../../common/QuestionTooltip";

const TableHeader = () => {
    const { t } = useTranslation();

    return (
        <thead>
            <tr className={classes.tr}>
                <th className={classes.th}>
                    <button>Add</button>
                </th>
                <th className={classes.th}>
                    <QuestionTooltip
                        textTooltip={t('maximumDensityInAOrderBookOfFuturesBuy')}
                        position='right'
                    >
                        F {t('buy')}
                    </QuestionTooltip>
                </th>
                <th className={classes.th}>
                    <QuestionTooltip
                        textTooltip={t('maximumDensityInAOrderBookOfFuturesSale')}
                        position='right'
                    >
                        F {t('sale')}
                    </QuestionTooltip>

                </th>
                <th className={classes.th}>
                    <QuestionTooltip
                        textTooltip={t('maximumDensityInAOrderBookOfSpotBuy')}
                        position='right'
                    >
                        S {t('buy')}
                    </QuestionTooltip>

                </th>
                <th className={classes.th}>
                    <QuestionTooltip
                        textTooltip={t('maximumDensityInAOrderBookOfSpotSale')}
                        position='right'
                    >
                        S {t('sale')}
                    </QuestionTooltip>
                </th>
            </tr>
        </thead>
    )
}

export default TableHeader;
