import React from 'react';
import {Spin} from "antd";
import classes from './BigSpinner.module.scss';

const BigSpinner = () => {
    return (
        <div className={classes.spinner}>
            <Spin tip="Loading..."/>
        </div>
    );
};

export default BigSpinner;
