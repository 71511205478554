import {combineReducers} from "@reduxjs/toolkit";
import {priceReducer} from "./priceReducer";
import {settingsScreenerReducer} from "./settingsScreenerReducer";
import {orderBookSpotReducer} from "./orderBookSpotReducer";
import {orderBookFuturesReducer} from "./orderBookFuturesReducer";
import {priceLineReducer} from "./priceLineReducer";
import {userReducer} from "./userReducer";
import {appReduce} from "./appReducer";
import {densityReduce} from "./density/densityReducer";
import {listSymbolReducer} from "./listSymbol/listSymbolReducer";

export default combineReducers({
    price: priceReducer,
    settingsScreener: settingsScreenerReducer,
    orderBookSpot: orderBookSpotReducer,
    orderBookFutures: orderBookFuturesReducer,
    priceLine: priceLineReducer,
    user: userReducer,
    app: appReduce,
    density: densityReduce,
    listSymbol: listSymbolReducer
});
