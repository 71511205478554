import React from 'react';
import classes from './CommonFilter.module.scss';
import SliderPercent from "../filter/SliderPercent";
import FilterFutures from "../filter/FilterFutures";
import FilterSpot from "../filter/FilterSpot";

const CommonFilterContent = () => {
    return (
        <div className={classes.content}>
            <div className={classes.section}>
                <SliderPercent/>
            </div>
            <div className={classes.filterWrap}>
                <div className={classes.section}>
                    <FilterFutures/>
                </div>
                <div className={classes.section}>
                    <FilterSpot/>
                </div>
            </div>
        </div>
    );
};

export default CommonFilterContent;
