import {CrosshairMode} from "lightweight-charts";


export const settingChart = {
    layout: {
        backgroundColor: 'rgba(0,0,0,0)',
        textColor: '#848e9c',
    },
    crosshair: {
        mode: CrosshairMode.Normal,
    },
    grid: {
        vertLines: {
            color: 'rgba(58,58,58,0.3)',
        },
        horzLines: {
            color: 'rgba(58,58,58,0.3)',
        },
    },
    timeScale: {
        borderColor: 'rgba(58,58,58,0.8)',
        timeVisible:true,
        secondsVisible:false,
    },
    priceFormat: {
        type: 'custom',
        minMove: '0.00001',
        // @ts-ignore
        formatter: (price) => {
            if (price < 0.00001) return parseFloat(price).toPrecision(8)
            else if (price >= 0.00001 && price < 1) return parseFloat(price).toPrecision(6)
            else return parseFloat(price).toPrecision(6)
        }
    },
    priceScale: {
        autoScale: true
    },
    localization: {
        locale: 'ru-RU',
        // @ts-ignore
        priceFormatter: (price) => {
            if (price < 0.00001) return parseFloat(price).toPrecision(8)
            else if (price >= 0.00001 && price < 1) return parseFloat(price).toPrecision(6)
            else return parseFloat(price).toPrecision(6)
        }
    },
}

export const settingSeries = {
    upColor: '#0ecb81',
    downColor: '#f6465d',
    borderDownColor: 'rgb(255,255,255, 0)',
    borderUpColor: 'rgb(255,255,255, 0)',
    wickDownColor: '#f6465d',
    wickUpColor: '#0ecb81',
}