import React, { FC } from "react";
import { Button } from "antd";
import classes from './AuthButtons.module.scss';
import { useTranslation } from "react-i18next";
import {Link} from "react-router-dom";

const AuthButtons:FC = () => {
    const { t } = useTranslation();

    return (
        <div className={classes.buttons}>
            <div className={classes.button}>
                <Link to="/login">
                    <Button ghost>{t('logIn')}</Button>
                </Link>
            </div>
            <div className={classes.button}>
                <Link to="/register">
                    <Button type="primary">{t('register')}</Button>
                </Link>
            </div>
        </div>
    );
};

export default AuthButtons;
