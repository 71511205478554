import React, {useCallback} from "react";
import {Avatar, Popover} from "antd";
import {signOut} from "firebase/auth";
import {authFireBase} from "../../../firebase";
import {useDispatch, useSelector} from "react-redux";
import {DELETE_USER_DATA} from "../../../store/actionTypes";
import classes from './UserInformation.module.scss'
import PopoverUserInformation from "./PopoverUserInformation";

const UserInformation = () => {

    const dispatch = useDispatch();
    // @ts-ignore
    const userData = useSelector(state => state.user);

    const onLogOut = useCallback(() => {
        signOut(authFireBase).then(() => {
            dispatch({
                type: DELETE_USER_DATA
            })
        })
    }, [dispatch]);

    return (
        <>

            <Popover
                content={<PopoverUserInformation handlerLogOut={onLogOut} userData={userData}/>}
                placement="bottomRight"
                overlayClassName={classes.popover}
                trigger={['click']}
            >
                <div>
                    {
                        !!userData.displayName && <Avatar className={classes.avatar} style={{ backgroundColor: '#5a9cff', verticalAlign: 'middle' }} size="large">
                            {userData.displayName[0]?.toUpperCase()}
                        </Avatar>
                    }

                </div>
            </Popover>
        </>
    );
};

export default UserInformation;
