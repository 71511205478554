import React, {FC, memo, useCallback, useEffect, useMemo, useState} from 'react';
import {Modal, Table} from 'antd';
import InputBase from '../inputs/InputBase';
import {symbols} from '../../constans/symbols';
import {useSelector} from 'react-redux';
import {ISymbolFromFB} from '../../types/ISymbols';
import Footer from './ModalFooter';
import classes from './ModalSymbolSearch.module.scss';
import Tags from "./Tags";

interface IModalSymbolSearch {
    visibleModal: boolean
    onToggleModal: () => void
    onSendSymbols: () => void
    setSelectedSymbol: (arr: string[]) => void
}

interface IColumn {
    key?: string
    title: string
    dataIndex: string
    width?: string
}

interface IRow {
    symbol: JSX.Element
    to: string
    source: string
    key: string
    coin: string
}

const ModalSymbolSearch:FC<IModalSymbolSearch> = ({ visibleModal, onToggleModal, onSendSymbols, setSelectedSymbol }) => {

    const listSymbol = useSelector((state: { listSymbol: ISymbolFromFB }) => state.listSymbol);
    const [selectedSymbol, setSelectedCheckbox] = useState<string[]>([]);

    const columns: IColumn[] = useMemo(() => {
        return [
            {
                title: 'Symbol',
                dataIndex: 'symbol',
            },
            {
                width: '30%',
                title: 'Source',
                dataIndex: 'source'
            }
        ]
    }, []);

    const data: IRow[] = useMemo(() => {
        const filteredList = symbols.filter(((el) => !listSymbol[el.symbol]));

        return filteredList.map((el) => {
            return {
                ...el,
                symbol: <div className={classes.tableTitle}>
                    <div className={classes.logo}>
                        <img src={`coins/${el.coin}.png`} alt={el.symbol.toUpperCase()}/>
                    </div>{el.symbol.toUpperCase()}
                </div>,
            }
        })
    }, [listSymbol]);

    useEffect(() => {
        setSelectedSymbol(selectedSymbol);
    }, [selectedSymbol, setSelectedSymbol]);

    const onChangeCheckbox = useCallback((selectedRowKeys: React.Key[], selectedRows: IRow[]) => {
        setSelectedCheckbox(() => selectedRows.map((el) => el.key));
    }, []);

    const onSend = useCallback(() => {
        onSendSymbols()
        setSelectedCheckbox([]);
    }, [onSendSymbols])

    return (
        <Modal
            transitionName=""
            title="Поиск символов"
            visible={visibleModal}
            onCancel={onToggleModal}
            className={classes.modal}
            width={840}
            footer={<Footer onSend={onSend} visible={!!selectedSymbol.length}/>}
            centered={true}
        >
            <div className={classes.inputSearch}>
                <InputBase></InputBase>
            </div>
            <Tags symbols={selectedSymbol}/>
            <Table
                columns={columns}
                dataSource={data}
                rowSelection={{
                    type: 'checkbox',
                    onChange: onChangeCheckbox,
                    selectedRowKeys: selectedSymbol
                }}
                scroll={{ y: 350 }}
                pagination={{ pageSize: 20 }}
                size="small"
                className={classes.table}
                onRow={(record) => (
                    {
                        onClick: () => {
                            setSelectedCheckbox((old) => {
                                return !old.includes(record.key) ? [...old, record.key] : old.filter(el => el !== record.key);
                            })
                        },
                    }
                )}
            ></Table>
        </Modal>
    );
};

export default memo(ModalSymbolSearch);
