import React, {FC, memo, useCallback, useMemo, useState} from 'react';
import classes from './Screener.module.scss';
import {Button} from "antd";
import {useTranslation} from "react-i18next";
import CustomTable from "../CustomTable";
import ModalSymbolSearch from "../ModalSymbolSearch";
import {ISymbolFromFB} from '../../types/ISymbols';
import {useFirebase} from "../../hooks/useFirebase";
import {useSelector} from "react-redux";
import {symbols} from "../../constans/symbols";


const ScreenerItem: FC<ISymbolFromFB> = ({ list = {} }) => {

    const { t } = useTranslation();
    const [selectedSymbol, setSelectedSymbol] = useState<string[]>([]);
    const onToggleModal = useCallback(() => {
        setModalVisible((position) => !position);
    }, []);
    // @ts-ignore
    const user = useSelector(state => state.user);

    const {getSymbolsListRef} = useFirebase()

    const empty = useMemo(() => {
        return (
            <Button onClick={onToggleModal} type="dashed" ghost>
                {t('addASymbol')}
            </Button>
        )
    }, [t, onToggleModal]);

    const [isModalVisible, setModalVisible] = useState(false);

    const listFiltered = useMemo(() => {
        return Object.values(list).sort((a, b) => {
            return a.index - b.index
        })
    }, [list]);

    const onSendSymbols = useCallback(async () => {
        setModalVisible(false);

        const symbolsListRef = getSymbolsListRef(user.uid)
        const res = symbols.filter((symbol => selectedSymbol.every(s => symbol.symbol.includes(s))))
        console.log('symbolsListRef', symbolsListRef);
        console.log('res', res);


        // await setDoc(symbolsListRef, {
        //
        // })

    }, [selectedSymbol, getSymbolsListRef, user.uid]);

    return (
        <>
            <div className={classes.screenerItem}>
                <div className={classes.content}>
                    <div className={classes.screenerItemContainer}>
                        {!listFiltered.length ? empty : <CustomTable data={listFiltered}/>}
                    </div>
                    <div className={classes.buttonAdd}>
                        <Button onClick={onToggleModal} type="dashed" ghost>
                            {t('addASymbol')}
                        </Button>
                    </div>
                </div>
            </div>
            <ModalSymbolSearch
                visibleModal={isModalVisible}
                onToggleModal={onToggleModal}
                onSendSymbols={onSendSymbols}
                setSelectedSymbol={setSelectedSymbol}
            />
        </>
    );
};

export default memo(ScreenerItem);
