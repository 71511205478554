import { DELETE_USER_DATA, SET_USER_DATA } from "../actionTypes";

const defaultState = {
    authorized: false
}

// @ts-ignore
export const userReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SET_USER_DATA:
            return {...state, ...action.payload, authorized: true};
        case DELETE_USER_DATA:
            return {authorized: false}
        default:
            return state;
    }
}