import React, { useCallback, useMemo } from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import classes from './LanguagesSelect.module.scss';
import {languages} from '../../constans/user';
const { Option } = Select;

const LanguagesSelect = () => {

  const { i18n } = useTranslation()

  const RenderOptions = useMemo(() => {
    return languages.map((el) => {
      return <Option key={el.value}>{el.title}</Option>
    })
  }, []);

  const handleChange = useCallback((value: string) => {
    i18n.changeLanguage(value);
  }, [i18n])

  return (
    <div className={classes.selectRow}>
      <Select style={{width: '62px'}} className={classes.select} defaultValue={i18n.language} onSelect={handleChange}>
        {RenderOptions}
      </Select>
    </div>
  );
};

export default LanguagesSelect;
