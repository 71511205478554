import {SET_ORDER_BOOK_FUTURES} from "../actionTypes";

const defaultState = {}

// @ts-ignore
export const orderBookFuturesReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SET_ORDER_BOOK_FUTURES:
            return {
                ...state,
                [action.payload.symbol]: action.payload.data
            }
        default:
            return state;
    }
}