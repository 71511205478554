import React, {FC, memo, useMemo} from "react";
import TableHeader from "./TableHeader/TableHeader";
import classes from "./Table.module.scss"
import RowTable from "./TableTr";
import {ISymbol} from "../../types/ISymbols";

interface ICustomTable {
    data: ISymbol[]
}

const CustomTable:FC<ICustomTable> = ({ data } ) => {
    const renderRowTable = useMemo(() => {
        return data.map((data: any) => {
            const { symbol } = data
            return <RowTable key={symbol} symbol={symbol} />
        })
    }, [data]);

    return (
        <div className={classes.table}>
            <table cellSpacing="0">
                <TableHeader/>
                <tbody>{renderRowTable}</tbody>
            </table>
        </div>
    )
}

export default memo(CustomTable);
