import {useMemo} from "react";
import {useSelector} from "react-redux";

const useWebSocketLinks = () => {

    // @ts-ignore
    const listSymbol = useSelector(state => state.listSymbol);
    const tradeSymbolsLinkSpot = useMemo(() => {
        return Object.values(listSymbol).map(data => {
            // @ts-ignore
            const { symbol } = data;
            return `/${symbol}@trade`
        }).join('');
    }, [listSymbol]);

    const depthSymbolsLinkSpot = useMemo(() => {
        return Object.values(listSymbol).map(data => {
            // @ts-ignore
            const {symbol} = data;
            return `/${symbol}@depth`
        }).join('');
    }, [listSymbol]);

    const depthSymbolsLinkFutures = useMemo(() => {
        return `?streams=${Object.values(listSymbol).map(data => {
            // @ts-ignore
            const { symbol } = data;
            return `${symbol}@depth/`
        }).join('')}`.slice(0, -1)
    }, [listSymbol])

    return {
        tradeSymbolsLinkSpot,
        depthSymbolsLinkSpot,
        depthSymbolsLinkFutures
    }
}

export default useWebSocketLinks;
