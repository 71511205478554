import React, { FC, memo } from "react";
import classes from "./ModalMoreInfoIcon.module.scss";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

interface IHeaderPrice {
    symbolUpperCase: string
}

const HeaderPrice:FC <IHeaderPrice> = ({ symbolUpperCase }) => {
    const { t } = useTranslation();
    const priceCurrent = useSelector((state:any) => state.price[symbolUpperCase]);
    return (
        <div className={classes.titlePrice}>{t('currentPrice')}: <span>{priceCurrent.toFixed(2)} USDT</span></div>
    );
};

export default memo(HeaderPrice);
