import React from "react";
import { Input } from "antd";
import classes from "./InputBase.module.scss";

const InputBase = (
    {
        ...props
    }) => {
    return (
        <Input  {...props} className={classes.input}/>
    );
};

export default InputBase;