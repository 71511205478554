import React from "react";
import AuthButtons from "../AuthButtons";

const AuthContainer = () => {
    return (
        <>
            <AuthButtons/>
        </>
    );
};

export default AuthContainer;
