import React, {FC, useCallback, useEffect, useState} from "react";
import {Alert, Button, Form} from "antd";
import {useTranslation} from "react-i18next";
import InputBase from "../../../components/inputs/InputBase";
import {authFireBase} from "../../../firebase";
import {signInWithEmailAndPassword} from "firebase/auth";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {GoogleOutlined} from "@ant-design/icons";
import useAuth from "../../../hooks/useAuth";

const LogIn:FC = () => {

    const { t } = useTranslation();

    useEffect(() => {
        document.title = t('logIn');
    }, [t]);

    const navigate = useNavigate();
    const { logInWidthGoogle } = useAuth();
    // @ts-ignore
    const authorized = useSelector(state => state.user.authorized);

    const [loadForm, setLoadForm] = useState(false);
    const [errors, setErrors] = useState('');

    const sendForm = useCallback(async (data: any) => {
        try {
            setLoadForm(true)
            await signInWithEmailAndPassword(authFireBase, data.email, data.password);
            setLoadForm(false);
        } catch (error) {
            setLoadForm(false);
            // @ts-ignore
            const message = `${error.response?.data?.code ?? error?.code ?? error?.response?.obj?.code ?? error?.name ?? 'somethingWentWrong'}`
            setErrors(message)

        }
    }, []);

    useEffect(() => {
        if (authorized) {
            navigate('/');
        }
    }, [navigate, authorized]);

    return (
        <>
            {
                !authorized &&
                <div className="auth">
                    <div>
                        <h1 className="text-center">{t('logIn')}</h1>
                        <Form.Item>
                            <Button
                                onClick={logInWidthGoogle}
                                size="large" icon={<GoogleOutlined />}
                                type="primary"
                                block
                                ghost
                            >{t('loginWithGoogle')}</Button>
                        </Form.Item>
                        <Form.Item>
                            <div className="or-in-form">
                                {t('or')}
                            </div>
                        </Form.Item>

                        <Form
                            name="normal_login"
                            initialValues={{ remember: true }}
                            onFinish={sendForm}
                            className="auth-form"
                        >
                            {
                                !!errors.length && <Form.Item>
                                    <Alert message={t(`auth.errors.${errors}`)} type="error" showIcon />
                                </Form.Item>
                            }

                            <Form.Item
                                name="email"
                                rules={[
                                    {
                                        type: 'email',
                                        message: t('auth.errors.The input is not valid E-mail!'),
                                    },
                                    {
                                        required: true,
                                        message: t('auth.errors.Please input your E-mail!'),
                                    },
                                ]}
                            >
                                <InputBase size="large" placeholder={t("email")} />
                            </Form.Item>
                            <Form.Item
                                name="password"
                                rules={[{ required: true, message: t('auth.errors.Please input your Password!') }]}
                            >
                                <InputBase size="large" type="password" placeholder={t('password')} />
                            </Form.Item>
                            <Form.Item>
                                <Button disabled={loadForm} size="large" block type="primary" htmlType="submit" className="login-form-button">
                                    {t('logIn')}
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            }

        </>
    );
};

export default LogIn;
