import React, {useEffect, useState} from 'react';
import SideBar from '../../components/SideBar';
import classes from './Main.module.scss';
import Screener from "../../components/Screener";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getDoc} from "firebase/firestore";
import {SET_SETTINGS} from "../../store/actionTypes";
import {useTranslation} from "react-i18next";
import {SET_SYMBOL_LIST} from "../../store/reducers/listSymbol/types";
import {useFirebase} from "../../hooks/useFirebase";

const Main = () => {

    const { t } = useTranslation();

    useEffect(() => {
        document.title = t('mainTitle');
    }, [t])

    const navigate = useNavigate();
    // @ts-ignore
    const user = useSelector(state => state.user);
    // @ts-ignore
    const app = useSelector(state => state.app);
    const [dataLoad, setDataLoad] = useState(false)
    useEffect(() => {
        if (!user.authorized) {
            navigate('/login');
        }
    }, [navigate, user.authorized]);

    const dispatch = useDispatch();
    const {getSettingsFilterRef, getSymbolsListRef} = useFirebase()

    useEffect(() => {
        if (user.authorized && app.checkCreatedUserDoc) {
            const generalSettingsRef = getSettingsFilterRef(user.uid);
            const listSymbolRef = getSymbolsListRef(user.uid);
            getDoc(generalSettingsRef).then((response) => {
                dispatch({
                    type: SET_SETTINGS,
                    payload: {
                        // @ts-ignore
                        ...response.data()
                    }
                });
                getDoc(listSymbolRef).then((response) => {
                    dispatch({
                        type: SET_SYMBOL_LIST,
                        payload: {
                            // @ts-ignore
                            ...response.data()
                        }
                    });
                    setDataLoad(true);
                });
            });

        }
    }, [user.uid, user.authorized, dispatch, app.checkCreatedUserDoc, getSettingsFilterRef, getSymbolsListRef]);

    return (
        <>
            {(user.authorized && dataLoad) &&
            <div className={classes.container}>
                <SideBar/>
                <Screener/>
            </div>}
        </>

    )
}

export default Main;
