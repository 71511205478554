import {
    SET_PERCENT, SET_SETTINGS,
    SET_VOLUME_MIN_FUTURES,
    SET_VOLUME_MIN_FUTURES_FILL,
    SET_VOLUME_MIN_SPOT,
    SET_VOLUME_MIN_SPOT_FILL
} from "../actionTypes";
import {generalSettings} from "../../consts";

export interface IDefaultState {
    percent: number,
    volumeMinSpot: number,
    volumeMinFutures: number,
    amountToFillSpot: number,
    amountToFillFutures: number,
    valueList: number[],
    screenerType: string
}

interface IAction {
    type: string,
    payload: IDefaultState
}

const defaultState: IDefaultState = generalSettings

export const settingsScreenerReducer = (state = defaultState, action: IAction) => {
    switch (action.type) {
        case SET_PERCENT:
            return {
                ...state,
                percent: action.payload.percent
            }
        case SET_VOLUME_MIN_SPOT:
            return {
                ...state,
                volumeMinSpot: action.payload.volumeMinSpot
            }
        case SET_VOLUME_MIN_FUTURES:
            return {
                ...state,
                volumeMinFutures: action.payload.volumeMinFutures
            }
        case SET_VOLUME_MIN_SPOT_FILL:
            return {
                ...state,
                amountToFillSpot: action.payload.amountToFillSpot
            }
        case SET_VOLUME_MIN_FUTURES_FILL:
            return {
                ...state,
                amountToFillFutures: action.payload.amountToFillFutures
            }
        case SET_SETTINGS:
            return {
                ...state,
                ...action.payload
            }


        default:
            return state;
    }
}
