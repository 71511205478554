import React, {memo, useCallback, useEffect, useState} from 'react';
import classes from "../../CommonFilter/CommonFilter.module.scss";
import {reduceNumber} from "../../../services/numberHelpers";
import CustomRow from "../../common/CustomRow";
import CustomInputNumber from "../../inputs/CustomInputNumber";
import {useDispatch, useSelector} from "react-redux";
import CheckableTag from "antd/lib/tag/CheckableTag";
import {SET_VOLUME_MIN_FUTURES, SET_VOLUME_MIN_FUTURES_FILL} from "../../../store/actionTypes";
import {IDefaultState} from "../../../store/reducers/settingsScreenerReducer";
import {useTranslation} from "react-i18next";
import {updateDoc} from "firebase/firestore";
import {useFirebase} from "../../../hooks/useFirebase";

const FilterFutures = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    // @ts-ignore
    const user = useSelector(state => state.user);
    const defaultSettings = useSelector((state: {settingsScreener: IDefaultState}) => state.settingsScreener);
    const [amountToFillFutures, setAmountToFillFutures] = useState(defaultSettings.amountToFillFutures);
    const {getSettingsFilterRef} = useFirebase();

    const handleChangeFuturesVolume = useCallback((volumeMinFutures: number) => {
        dispatch({
            type: SET_VOLUME_MIN_FUTURES,
            payload: {
                volumeMinFutures
            }
        });
        const userRef = getSettingsFilterRef(user.uid);
        updateDoc(userRef, {
            volumeMinFutures
        }).then(() => {

        });
    }, [dispatch, user.uid, getSettingsFilterRef]);

    useEffect(() => {
        dispatch({
            type: SET_VOLUME_MIN_FUTURES_FILL,
            payload: {
                amountToFillFutures
            }
        });

        const userRef = getSettingsFilterRef(user.uid);
        updateDoc(userRef, {
            amountToFillFutures
        }).then(() => {

        });
    }, [dispatch, amountToFillFutures, user.uid, getSettingsFilterRef]);

    return (
        <>
            <h3>{t('futures')}</h3>
            <label className={classes.label}>{t('minimumVolume')}:</label>
            {defaultSettings.valueList.map((value: any) => {
                return (
                    <CheckableTag
                        key={value}
                        checked={defaultSettings.volumeMinFutures === value }
                        onChange={() => handleChangeFuturesVolume(value)}
                    >{reduceNumber(value, 1)}</CheckableTag>
                )
            })}
            <CustomRow>
                <CustomInputNumber
                    value={amountToFillFutures}
                    onChange={setAmountToFillFutures}
                    label={`${t('minimumVolumeToFill')}:`}
                />
            </CustomRow>
        </>
    );
};

export default memo(FilterFutures);
