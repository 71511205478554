import React, {useEffect, useMemo, useRef, useState} from 'react';
import classes from './Density.module.scss';
import DensityEmpty from "./DensityEmpty";
import {useSelector} from "react-redux";
import {Tag} from "antd";
import HeaderPrice from "../modals/ModalMoreInfoIcon/HeaderPrice";
import {useTranslation} from "react-i18next";
import BigChart from "../BigChart";

const Density = () => {

    const { t } = useTranslation();

    // @ts-ignore
    const density = useSelector((state) => state.density);
    const [heightChart, setHeightChart] = useState<number>(340);
    const orderCupRef = useRef<HTMLDivElement>(null);
    // const orderCupHeight = useMemo(() => orderCupRef.current?.clientHeight, [orderCupRef.current?.clientHeight]);
    const symbolUpperCase = useMemo(() => {
        return `${density.symbol.toUpperCase()}`;
    }, [density.symbol]);

    useEffect(() => {
        if (orderCupRef.current?.clientHeight) {
            return setHeightChart((orderCupRef.current.clientHeight / 2) - 23);
        }
    }, [orderCupRef]);

    return (
        <div ref={orderCupRef} className={classes.density}>
            {
                !!density.price ?
                    <div className={classes.body}>
                        <header className={classes.header}>
                            <Tag color={density.side === 'ask' ? '#e32648' : '#0c652b'}>{density.symbol.toUpperCase()} </Tag>
                            <div className="display-flex">
                                <HeaderPrice symbolUpperCase={symbolUpperCase}/>
                                <span>{density.type}</span>
                            </div>
                        </header>
                        <div className={classes.content}>
                            <div className={classes.info}>
                                <div>
                                    <b>{t('price')}</b>: {density.price} USDT
                                </div>
                                <div>
                                    <b>{t('volume')}</b>: ${density.value}
                                </div>
                                <div>
                                    <b>{t('coins')}</b>: {density.coins}
                                </div>
                                <div>
                                    <b>{t('percent')}</b>: {density.percent}
                                </div>
                            </div>
                        </div>
                        <div>
                            <BigChart
                                height={heightChart}
                                regularSymbol={density.symbol}
                                upperCaseSymbol={symbolUpperCase}
                                defaultInterval={'15m'}
                            />
                            <BigChart
                                height={heightChart}
                                regularSymbol={density.symbol}
                                upperCaseSymbol={symbolUpperCase}
                                defaultInterval={'1h'}
                            />
                        </div>


                    </div>
                    : <DensityEmpty/>
            }

        </div>
    );
};

export default Density;
