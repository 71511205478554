import React, {FC, memo} from 'react';
import {Button} from "antd";

interface IModalFooter {
    visible: boolean
    onSend: () => void
}

const ModalFooter:FC <IModalFooter> = ({ visible, onSend }) => {
    return (
        <div>
            {visible && <Button onClick={onSend} type="primary">Save</Button>}
        </div>
    );
};

export default memo(ModalFooter);
