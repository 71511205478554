export const generalSettings = {
    percent: 1,
    volumeMinSpot: 300000,
    volumeMinFutures: 300000,
    amountToFillSpot: 1000000,
    amountToFillFutures: 1000000,
    valueList: [100000, 300000, 500000, 1000000],
    screenerType: "onlyDensities"
}

export const SYMBOL_DEFAULT_SETTINGS = {
    'btc': {
        valueMin: 1000000
    },
    'eth': {
        valueMin: 1000000
    },
    'luna': {
        valueMin: 500000
    },
    'sol': {
        valueMin: 200000
    }
}

export const tradeTypes = {
    SPOT: "spot",
    FUTURES: "futures"
}
