import {cond, equals} from "ramda";
import Big from "big.js";
import {maxValueFromArr} from "./numberHelpers";

export const appendUpdatedId = (updateId: number, ask: (number)[][] , bid: (number)[][]) => {
    const insertUpdateId = (order: (number)[]) => {
        order[2] = updateId;
        return order;
    };
    return [ask, bid].map((side) => side.map(insertUpdateId));
};

export const filteringPriceAndVolume = (list: (number)[][], pricePercent: number, countMin: number, type: string) => {

    return list.filter((item) => {
        const [price, value] = item;
        const priceFloat = price;
        const valueFloat = value;

        if (type === 'plus') {
            return (priceFloat <= pricePercent) && (priceFloat * valueFloat >= (countMin <= 50000 ? 50000 : countMin))
        }
        if (type === 'minus') {
            return (priceFloat >= pricePercent) && (priceFloat * valueFloat >= (countMin <= 50000 ? 50000 : countMin ))
        }
        return [];
    })
}

export const filterPriceAndVolume = ( volume: (string)[][], oldVolume: number[], pricePercent: number, valueMin: number, type: string ) => {

    const newVolume = volume.map(el => el.map(Number));
    const sameAskPrice = () => {
        if (oldVolume.length) {
            return newVolume.filter((item: number[]) => oldVolume[0] === item[0]);
        }
        return [];
    }

    const maxNewVolume = () => {
        const max = newVolume.filter(el => {
            const [price, value] = el;
            if (type === 'ask') {
                return (price <= pricePercent) && (price * value >= valueMin);
            }
            if (type === 'bid') {
                return (price >= pricePercent) && (price * value >= valueMin);
            }
            return [];
        })
        return maxValueFromArr(max);
    }
    const result = () => {
        if (!oldVolume.length) {
            return maxNewVolume();
        }
        if (sameAskPrice().length) {
            const valueSame = sameAskPrice()[0][1];
            const valueNew = maxNewVolume()[1];
            if (valueSame === 0 && valueNew !== undefined) {
                return maxNewVolume();
            }
            if (valueSame >= valueNew && valueNew !== undefined) {
                return sameAskPrice()[0];
            }
            if (valueSame < valueNew) {
                return maxNewVolume();
            } else {
                return maxNewVolume();
            }
        }
        if(oldVolume.length) {
            const [price, value] = oldVolume
            if (type === 'ask' && (price <= pricePercent) && (price * value >= valueMin)) {
                return oldVolume;
            }
            if (type === 'bid' && (price >= pricePercent) && (price * value >= valueMin)) {
                return oldVolume;
            }
        }

        return [];

    }
    return result();
}


export const compareValueFn = cond([
    [equals<string>('ask'), () => (a: string[], b: string[]) => (new Big(a[0])).minus(b[0])],
    [equals<string>('bid'), () => (a: string[], b: string[]) => (new Big(b[0])).minus(a[0])],
]);