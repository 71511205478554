import React, {useCallback, useEffect, useState} from 'react';
import classes from "../../CommonFilter/CommonFilter.module.scss";
import {reduceNumber} from "../../../services/numberHelpers";
import CustomRow from "../../common/CustomRow";
import CustomInputNumber from "../../inputs/CustomInputNumber";
import CheckableTag from "antd/lib/tag/CheckableTag";
import {useDispatch, useSelector} from "react-redux";
import {SET_VOLUME_MIN_SPOT, SET_VOLUME_MIN_SPOT_FILL} from "../../../store/actionTypes";
import {IDefaultState} from "../../../store/reducers/settingsScreenerReducer";
import {useTranslation} from "react-i18next";
import {updateDoc} from "firebase/firestore";
import {useFirebase} from "../../../hooks/useFirebase";

const FilterSpot = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const defaultValueList = useSelector((state:{settingsScreener: IDefaultState}) => state.settingsScreener.valueList);
    const volumeMinSpot = useSelector((state:{settingsScreener: IDefaultState}) => state.settingsScreener.volumeMinSpot);
    const defaultAmountToFillSpot = useSelector((state:{settingsScreener: IDefaultState}) => state.settingsScreener.amountToFillSpot)
    // @ts-ignore
    const user = useSelector(state => state.user);
    const [amountToFillSpot, setAmountToFillSpot] = useState(defaultAmountToFillSpot);
    const {getSettingsFilterRef} = useFirebase()

    const handlerChangeSpotVolume = useCallback((volumeMinSpot: any) => {
        dispatch({
            type: SET_VOLUME_MIN_SPOT,
            payload: {
                volumeMinSpot
            }
        });
        const userRef = getSettingsFilterRef(user.uid);
        updateDoc(userRef, {
            volumeMinSpot
        }).then(() => {

        });
    }, [dispatch, user.uid, getSettingsFilterRef]);

    useEffect(() => {
        dispatch({
            type: SET_VOLUME_MIN_SPOT_FILL,
            payload: {
                amountToFillSpot
            }
        })
        const userRef = getSettingsFilterRef(user.uid);
        updateDoc(userRef, {
            amountToFillSpot
        }).then(() => {

        });
    }, [dispatch, amountToFillSpot, user.uid, getSettingsFilterRef]);

    return (
        <>
            <h3>{t('spot')}</h3>
            <label className={classes.label}>{t('minimumVolume')}:</label>
            {defaultValueList.map((value: any) => {
                return (
                    <CheckableTag
                        key={value}
                        checked={volumeMinSpot === value }
                        onChange={() => handlerChangeSpotVolume(value)}
                    >{reduceNumber(value, 1)}</CheckableTag>
                )
            })}
            <CustomRow>
                <CustomInputNumber
                    value={amountToFillSpot}
                    onChange={setAmountToFillSpot}
                    label={`${t('minimumVolumeToFill')}:`}
                />
            </CustomRow>
        </>
    );
};

export default FilterSpot;
