import React, {FC, memo, useEffect, useMemo, useState} from 'react';
import classes from "./BigChart.module.scss";
import {getKline} from "../../services/api/modules/orderBookSpot";
import {Col} from "antd";
import ChartComponent from "./ChartComponent";
import useWebSocket from "../../hooks/useWebSocket";
import cx from "classnames";

interface BigChartProps {
    upperCaseSymbol: string,
    regularSymbol: string,
    height: number,
    defaultInterval?: string
}

export interface IRestResponse {
    time: number,
    open: number,
    high: number,
    low: number,
    close: number
    date?: number
}

const intervals = [
    {title: '5м', value: '5m'},
    {title: '15м', value: '15m'},
    {title: '1ч', value: '1h'},
    {title: '1д', value: '1d'},
]

const BigChart: FC<BigChartProps> = (
    {
        upperCaseSymbol,
        regularSymbol,
        height,
        defaultInterval = '15m'
    }) => {

    const [restResponse, setRestResponse] = useState<IRestResponse[]>([]);
    const [wsResponse, setWsResponse] = useState<IRestResponse | undefined>();
    const [interval, setInterval] = useState<string>(defaultInterval);
    const { streamKline } = useWebSocket();

    useEffect(() => {
        getKline(upperCaseSymbol, interval).then(res => {

            const cdata = res.data.map((d: (any)[]) => {
                return {
                    time: d[0] / 1000,
                    open: parseFloat(d[1]),
                    high: parseFloat(d[2]),
                    low: parseFloat(d[3]),
                    close: parseFloat(d[4]),
                    date: d[0]
                }
            });
            setRestResponse(cdata);
        })
    }, [upperCaseSymbol, interval]);


    const klineStream = useMemo(() => {
        return streamKline(regularSymbol, interval);
    }, [regularSymbol, interval, streamKline])

    useEffect(() => {
        klineStream.onmessage = event => {
            const data = JSON.parse(event.data)
            setWsResponse({
                time: Math.round(data.k['t']/1000),
                open: parseFloat(data.k['o']),
                high: parseFloat(data.k['h']),
                low:parseFloat(data.k['l']),
                close:parseFloat(data.k['c'])
            });
        }

        return () => {
            klineStream.close()
        }

    }, [regularSymbol, interval, klineStream, wsResponse]);

    useEffect(() => {
        restResponse.map((el) => {
            if (el.open > el.close) {
                const result1 = ((el.high-el.open)/el.high)*100;
                const result2 = ((el.low-el.close)/el.low)*100;
                // @ts-ignore
                const data = new Date(el.date)
                if (result1 >= 0.9 || result1 >= 0.9) {
                    console.log(`red - ${result1}% ${result2}%- ${data}`)
                }
            } else {
                // const result = ((el.close-el.open)/el.close)*100;
                // // @ts-ignore
                // const data = new Date(el.date)
                // if (result >= 0.5) {
                //     console.log(`green - ${result}% - ${data}`)
                // }
            }

            return 1
        })
    }, [restResponse]);

    return (
        <Col className={classes.chartContainer}>
            <div className={classes.intervals}>
                {intervals.map((item) => {
                    return <span
                        key={item.value}
                        onClick={() => setInterval(item.value)}
                        className={cx(classes.interval, {[classes.intervalActive]: interval === item.value})}
                    >{item.title}</span>
                })}
            </div>
            <ChartComponent
                height={height}
                dataRest={restResponse}
                dataWs={wsResponse}/>
        </Col>

    );
};

export default memo(BigChart);
