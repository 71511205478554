import React, {FC} from "react";
import classes from './CustomRow.module.scss';

interface CustomRowProps {
    children: React.ReactNode;
}

const CustomRow: FC<CustomRowProps> =
    ({
         children
     }) => {
        return (
            <div className={classes.row}>
                {children}
            </div>
        )
    }


export default CustomRow;