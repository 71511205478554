import {SET_DENSITY_INFO} from "./types";

interface IState {
    symbol: string
    type: string
    side: string
}
interface IActions {
    type: string,
    payload: any
}
const defaultState: IState = {
    symbol: '',
    type: '',
    side: ''
}

//TODO: add types
export const densityReduce = (state = defaultState, action: IActions) => {
    switch (action.type) {
        case SET_DENSITY_INFO:
            return {...state, ...action.payload}
        default:
            return state;
    }
}
