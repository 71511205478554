import {doc} from "firebase/firestore";
import {dbFirebase} from "../firebase";
import {useCallback} from "react";

export const useFirebase = () => {
    const getUserRef = (uid: string) => {
        return doc(dbFirebase, "users", uid)
    }

    const getSettingsFilterRef = useCallback ((uid: string) => {
        return doc(dbFirebase, "users", uid, 'settings', 'filter')
    }, [])

    const getSymbolsListRef = (uid: string) => {
        return doc(dbFirebase, "users", uid, 'symbols', 'list')
    }

    return {
        getUserRef,
        getSettingsFilterRef,
        getSymbolsListRef
    }
}
