import React, {useCallback} from 'react';
import classes from "../../CommonFilter/CommonFilter.module.scss";
import {Slider} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {SET_PERCENT} from "../../../store/actionTypes";
import {IDefaultState} from "../../../store/reducers/settingsScreenerReducer";
import { useTranslation } from "react-i18next";
import {doc, updateDoc} from "firebase/firestore";
import {dbFirebase} from "../../../firebase";

const SliderPercent = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const percent = useSelector((state: { settingsScreener: IDefaultState }) => state.settingsScreener.percent);
    // @ts-ignore
    const user = useSelector(state => state.user);
    const handleChangePercent = useCallback((percent: any) => {
        dispatch({
            type: SET_PERCENT,
            payload: {percent}
        });
        const userRef = doc(dbFirebase, "users", user.uid, 'general-settings');
        updateDoc(userRef, {
            percent
        }).then(() => {

        });
    }, [dispatch, user.uid]);

    return (
        <>
            <h3>{t('generalSettings')}</h3>
            <label className={classes.label} >
                {t('filterByDistanceToALargeVolume')}: ≤ {percent}%
            </label>
            <Slider onChange={handleChangePercent} max={5} min={0.1} step={0.1} value={percent} />
        </>
    );
};

export default SliderPercent;
