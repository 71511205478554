import React from "react";
import classes from './Logo.module.scss';
import {Link} from "react-router-dom";

const Logo = () => {
    return (
        <span className={classes.logo}>
            <Link to='/'>
                 <img src="logo.png" alt="Crypto screener" title="Crypto screener"/>
            </Link>
        </span>
    )
}

export default Logo;
