import React, {useEffect, useRef} from 'react';
import classes from './Screener.module.scss';
import ScreenerItem from "./ScreenerItem";
import {SET_ORDER_BOOK_FUTURES, SET_ORDER_BOOK_SPOT, SET_PRICE_CURRENCY} from "../../store/actionTypes";
import {useDispatch, useSelector} from "react-redux";
import useWebSocket from "../../hooks/useWebSocket";
import useWebSocketLinks from "../../hooks/useWebSocketLinks";
import Density from "../Density";

const Screener = () => {
    const { streamTrade, streamDepth, streamDepthFutures } = useWebSocket();
    const { tradeSymbolsLinkSpot, depthSymbolsLinkSpot, depthSymbolsLinkFutures } = useWebSocketLinks();
    const bookStreamFutures = useRef<any>();
    const bookStreamSpot = useRef<any>();
    // @ts-ignore
    const listSymbol = useSelector(state => state.listSymbol);
    const dispatch = useDispatch();

    useEffect(() => {
        const tradeStream = streamTrade(tradeSymbolsLinkSpot);
        tradeStream.onmessage = event => {
            const data = JSON.parse(event.data);

            dispatch(
                {
                    type: SET_PRICE_CURRENCY,
                    payload: {
                        symbol: data['s'],
                        price: parseFloat(data['p'])
                    }
                })
        }

    }, [dispatch, streamTrade, tradeSymbolsLinkSpot]);

    useEffect(() => {
        bookStreamSpot.current = streamDepth(depthSymbolsLinkSpot);
    }, [depthSymbolsLinkSpot, streamDepth]);

    useEffect(() => {
        bookStreamSpot.current.onmessage = (event: any) => {
            const data = JSON.parse(event.data);
            const symbol = data['s']
            dispatch({
                type: SET_ORDER_BOOK_SPOT,
                payload: {
                    symbol,
                    data: data
                }
            })
        }
    }, [dispatch]);

    useEffect(() => {
        bookStreamFutures.current = streamDepthFutures(depthSymbolsLinkFutures);
    }, [depthSymbolsLinkFutures, streamDepthFutures]);

    useEffect(() => {
        bookStreamFutures.current.onmessage = (event: any) => {
            const data = JSON.parse(event.data).data;
            const symbol = data['s']
            dispatch({
                type: SET_ORDER_BOOK_FUTURES,
                payload: {
                    symbol,
                    data: data
                }
            })
        }
    }, [dispatch])


    return (
        <div className={classes.screener}>
            <ScreenerItem list={listSymbol}/>
            <Density/>
        </div>
    );
};

export default Screener;
