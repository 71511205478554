export const symbols = [
    {
        index: 1,
        key: 'btcusdt',
        symbol: 'btcusdt',
        coin: 'btc',
        to: 'usdt',
        source: 'binance'
    },
    {
        key: 'ethusdt',
        symbol: 'ethusdt',
        coin: 'eth',
        to: 'usdt',
        source: 'binance'
    },
    {
        key: 'bnbusdt',
        symbol: 'bnbusdt',
        coin: 'bnb',
        to: 'usdt',
        source: 'binance'
    },
    {
        key: 'xrpusdt',
        symbol: 'xrpusdt',
        coin: 'xrp',
        to: 'usdt',
        source: 'binance'
    },
    {
        key: 'adausdt',
        symbol: 'adausdt',
        coin: 'ada',
        to: 'usdt',
        source: 'binance'
    },
    {
        key: 'solusdt',
        symbol: 'solusdt',
        coin: 'sol',
        to: 'usdt',
        source: 'binance'
    },
    {
        key: 'dogeusdt',
        symbol: 'dogeusdt',
        coin: 'doge',
        to: 'usdt',
        source: 'binance'
    },
    {
        key: 'dotusdt',
        symbol: 'dotusdt',
        coin: 'dot',
        to: 'usdt',
        source: 'binance'
    },
    {
        key: 'daiusdt',
        symbol: 'daiusdt',
        coin: 'dai',
        to: 'usdt',
        source: 'binance'
    },
    {
        key: 'maticusdt',
        symbol: 'maticusdt',
        coin: 'matic',
        to: 'usdt',
        source: 'binance'
    },
    {
        key: 'shibusdt',
        symbol: 'shibusdt',
        coin: 'shib',
        to: 'usdt',
        source: 'binance'
    },
    {
        key: 'trxusdt',
        symbol: 'trxusdt',
        coin: 'trx',
        to: 'usdt',
        source: 'binance'
    },
    {
        key: 'avaxusdt',
        symbol: 'avaxusdt',
        coin: 'avax',
        to: 'usdt',
        source: 'binance'
    },
    {
        key: 'leousdt',
        symbol: 'leousdt',
        coin: 'leo',
        to: 'usdt',
        source: 'binance'
    },
    {
        key: 'wbtcusdt',
        symbol: 'wbtcusdt',
        coin: 'wbtc',
        to: 'usdt',
        source: 'binance'
    },
    {
        key: 'uniusdt',
        symbol: 'uniusdt',
        coin: 'uni',
        to: 'usdt',
        source: 'binance'
    },
    {
        key: 'etcusdt',
        symbol: 'etcusdt',
        coin: 'etc',
        to: 'usdt',
        source: 'binance'
    },
    {
        key: 'ltcusdt',
        symbol: 'ltcusdt',
        coin: 'ltc',
        to: 'usdt',
        source: 'binance'
    },
    {
        key: 'fttusdt',
        symbol: 'fttusdt',
        coin: 'ftt',
        to: 'usdt',
        source: 'binance'
    },
    {
        key: 'atomusdt',
        symbol: 'atomusdt',
        coin: 'atom',
        to: 'usdt',
        source: 'binance'
    },
    {
        key: 'linkusdt',
        symbol: 'linkusdt',
        coin: 'link',
        to: 'usdt',
        source: 'binance'
    },
    {
        key: 'nearusdt',
        symbol: 'nearusdt',
        coin: 'near',
        to: 'usdt',
        source: 'binance'
    },
    {
        key: 'crousdt',
        symbol: 'crousdt',
        coin: 'cro',
        to: 'usdt',
        source: 'binance'
    }
]
