import React, {memo} from "react";
import classes from "./Header.module.scss";
import Logo from "../Logo";
import LanguagesSelect from "../LanguagesSelect";
import AuthContainer from "../auth/AuthContainer/AuthContainer";
import { useSelector } from "react-redux";
import UserInformation from "../user/UserInformation";
import {WarningOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";

const Header = () => {

    const { t } = useTranslation();
    // @ts-ignore
    const authorized = useSelector(state => state.user.authorized);

    return (
        <header className={classes.header}>
            <div className="container">
                <div className={classes.headerRow}>
                    <div className={classes.item}>
                        <Logo/>
                    </div>
                    <div className={classes.item}>
                        <div className={classes.warning}>
                            <span className={classes.warningIcon}>
                                <WarningOutlined style={{color: "#faad14"}}/>
                            </span>
                            <span>{t('Site is under construction')}</span>
                            <a rel="noreferrer" target="_blank" href="https://t.me/voloshyn_oo">{t('Have questions?')}</a>
                        </div>
                        <LanguagesSelect/>
                        {!authorized && <AuthContainer/>}
                        {authorized && <UserInformation/>}
                    </div>
                </div>
            </div>
        </header>
    )
}

export default memo(Header);
