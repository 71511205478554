import React, {FC, memo, useCallback, useEffect, useMemo, useState} from "react";
import classes from "./RowTable.module.scss";
import cx from 'classnames';
import {useDispatch, useSelector} from "react-redux";
import {filterPriceAndVolume} from "../../../services/orderBookHelpers";
import {Popover} from "antd";
import Td from "./Td";
import {ReloadOutlined, RestOutlined, SettingOutlined} from "@ant-design/icons";
import {getOrderBookF, getOrderBookSpot} from "../../../services/api/modules/orderBookSpot";
import {deleteField, getDoc, updateDoc} from "firebase/firestore";
import {REMOVE_SYMBOL} from "../../../store/reducers/listSymbol/types";
import {useFirebase} from "../../../hooks/useFirebase";

interface IRowTable {
    symbol: string
}

const RowTable:FC <IRowTable> = ({symbol}) => {

    const symbolUpperCase = useMemo(() => {
        return `${symbol.toUpperCase()}`
    }, [symbol]);

    // @ts-ignore
    const price = useSelector(state => state.price[symbolUpperCase]);
    // @ts-ignore
    const percent = useSelector(state => state.settingsScreener.percent);
    // @ts-ignore
    const volumeMinSpot = useSelector(state => state.settingsScreener.volumeMinSpot);
    // @ts-ignore
    const volumeMinFutures = useSelector(state => state.settingsScreener.volumeMinFutures);
    // const
    // @ts-ignore
    const spot = useSelector(state => state.orderBookSpot[symbolUpperCase]);
    // @ts-ignore
    const futures = useSelector(state => state.orderBookFutures[symbolUpperCase]);
    // @ts-ignore
    const density = useSelector((state) => state.density);
    // @ts-ignore
    const user = useSelector(state => state.user);
    const dispatch = useDispatch();

    const [spotAskMax, setSpotAskMax] = useState<any>([])
    const [spotBidMax, setSpotBidMax] = useState<any>([])

    const [futuresAskMax, setFuturesAskMax] = useState<any>([])
    const [futuresBidMax, setFuturesBidMax] = useState<any>([])

    const [priceAskPercent, setPriceAskPercent] = useState<number>(0);
    const [priceBidPercent, setPriceBidPercent] = useState<number>(0);

    const {getSymbolsListRef} = useFirebase()

    const selectedSymbol = useMemo(() => {
        return density.symbol === symbol;
    }, [symbol, density.symbol]);

    const callOrderBookSpot = useCallback(() => {
        getOrderBookSpot(symbolUpperCase, 5000).then(res => {
            const ask = res.data.asks;
            const bid = res.data.bids;
            setSpotAskMax((spotAskRead: number[]) => {
                return filterPriceAndVolume(ask, spotAskRead, priceAskPercent, volumeMinSpot, 'ask');
            });
            setSpotBidMax((spotBidRead: number[]) => {
                return filterPriceAndVolume(bid, spotBidRead, priceBidPercent, volumeMinSpot, 'bid');
            })
        })
    }, [priceBidPercent,priceAskPercent, volumeMinSpot, symbolUpperCase]);

    const callOrderBookF = useCallback(() => {
        getOrderBookF(symbolUpperCase, 1000).then(res => {
            const ask = res.data.asks;
            const bid = res.data.bids;
            setSpotAskMax((spotAskRead: number[]) => {
                return filterPriceAndVolume(ask, spotAskRead, priceAskPercent, volumeMinFutures, 'ask');
            });
            setSpotBidMax((spotBidRead: number[]) => {
                return filterPriceAndVolume(bid, spotBidRead, priceBidPercent, volumeMinFutures, 'bid');
            })
        })
    }, [priceAskPercent,priceBidPercent, volumeMinFutures, symbolUpperCase]);

    const callUpdate = useCallback(() => {
        callOrderBookSpot();
        callOrderBookF();
    }, [callOrderBookSpot, callOrderBookF])

    useEffect(() => {
        setPriceBidPercent(() => {
            const intervalPriceMinus = (price / 100) * percent
            return price - intervalPriceMinus;
        });

        setPriceAskPercent(() => {
            const intervalPricePlus = (price / 100) * percent
            return price + intervalPricePlus
        })
    }, [price, percent]);

    useEffect(() => {
        if (spot) {
            const ask = spot['a'];
            setSpotAskMax((spotAskRead: number[]) => {
                return filterPriceAndVolume(ask, spotAskRead, priceAskPercent, volumeMinSpot, 'ask');
            });
        }
    }, [spot, priceAskPercent, volumeMinSpot]);

    useEffect(() => {
        if (futures) {
            const ask = futures['a'];
            setFuturesAskMax((oldAsk: number[]) => {
                return filterPriceAndVolume(ask, oldAsk, priceAskPercent, volumeMinFutures, 'ask');
            })
        }
    }, [futures, priceAskPercent, volumeMinFutures]);

    useEffect(() => {
        if (spot) {
            const bid = spot['b'];
            setSpotBidMax((spotBidRead: number[]) => {
                return filterPriceAndVolume(bid, spotBidRead, priceBidPercent, volumeMinSpot, 'bid');
            })
        }
    }, [spot, priceBidPercent, volumeMinSpot]);

    useEffect(() => {
        if (futures) {
            const bid = futures['b'];
            setFuturesBidMax((oldBid: number[]) => {
                return filterPriceAndVolume(bid, oldBid, priceBidPercent, volumeMinFutures, 'bid');
            })
        }
    }, [futures, priceBidPercent, volumeMinFutures]);

    const remove = useCallback(async () => {
        const userRef = getSymbolsListRef(user.uid);
        const listSymbolRef = getSymbolsListRef(user.uid);
        try {
            await updateDoc(userRef, {
                [symbol]: deleteField()
            });
            const responseList = await getDoc(listSymbolRef)
            dispatch({
                type: REMOVE_SYMBOL,
                payload: responseList.data()
            })

        } catch (e) {
            console.log(e)
        }

    }, [symbol, user.uid, dispatch, getSymbolsListRef]);

    const contentPopover = useMemo(() => {
        return (
            <div>
                <ul className={classes.list}>
                    <li onClick={callUpdate}>
                        <ReloadOutlined />
                        <span className={classes.menuTitle}>
                            Update
                        </span>
                    </li>
                    <li>
                        <SettingOutlined />
                        <span className={classes.menuTitle}>
                            Settings
                        </span>
                    </li>
                    <li onClick={remove}>
                        <RestOutlined />
                        <span className={classes.menuTitle}>
                            Remove
                        </span>
                    </li>
                </ul>
            </div>
        )
    }, [callUpdate, remove]);

    return (
        <>
            <tr className={classes.tr}>
                <Popover
                    overlayClassName={classes.popover}
                    trigger={['click']}
                    placement="rightTop"
                    title={symbolUpperCase}
                    content={contentPopover}>
                    <td className={cx(classes.td, classes.symbol, {[classes.symbolActive]: selectedSymbol})}>{symbol.toUpperCase()}</td>
                </Popover>
                <Td
                    data={futuresBidMax}
                    priceCurrent={price}
                    symbol={symbol}
                    side='bid'
                    type='futures'
                />
                <Td
                    data={futuresAskMax}
                    priceCurrent={price}
                    symbol={symbol}
                    side='ask'
                    type='futures'
                />
                <Td
                    data={spotBidMax}
                    priceCurrent={price}
                    symbol={symbol}
                    side='bid'
                    type='spot'
                />
                <Td
                    data={spotAskMax}
                    priceCurrent={price}
                    symbol={symbol}
                    side='ask'
                    type='spot'
                />
            </tr>
        </>

    )
}

export default memo(RowTable);
