import React, {FC, memo, useCallback, useEffect, useMemo} from "react";
import classes from "./RowTable.module.scss";
import {reduceNumber} from "../../../services/numberHelpers";
import cx from "classnames";
import {ISide} from "../../OrderCup/OrderCupItem";
import {useDispatch, useSelector} from "react-redux";
import {IDefaultState} from "../../../store/reducers/settingsScreenerReducer";
import {SET_DENSITY_INFO} from "../../../store/reducers/density/types";
import {CaretRightOutlined} from "@ant-design/icons";
import {SET_PRICE_LINE} from "../../../store/actionTypes";

interface Itd {
    data: (number)[]
    priceCurrent: number
    symbol: string
    side: string
    type: string
}

const Td:FC <Itd> = (
    {
        data = [],
        priceCurrent,
        symbol,
        side,
        type
    }) => {

    const amountToFillSpot = useSelector((state:{ settingsScreener: IDefaultState }) => state.settingsScreener.amountToFillSpot);
    const amountToFillFutures = useSelector((state:{ settingsScreener: IDefaultState }) => state.settingsScreener.amountToFillFutures);
    // @ts-ignore
    const density = useSelector((state) => state.density);
    const dispatch = useDispatch()

    const selectedSymbolSideType = useMemo(() => {
        return `${density.symbol}_${density.type}_${density.side}` === `${symbol}_${type}_${side}`;
    }, [density, symbol, type, side]);

    const value = useMemo(() => {
        if (data.length) {
            const [price, value] = data;
            return reduceNumber((price * value), 1);
        }
        return;
    }, [data]);

    const price = useMemo(() => {
        if (data.length){
            return data[0];
        }
        return
    }, [data]);

    const coins = useMemo(() => {
        if(data.length) {
            return data[1];
        }
    }, [data])

    const percent = useMemo(() => {
        if (data.length && priceCurrent) {
            const [price] = data;
            return (price < priceCurrent ? ((priceCurrent-price)/price) * 100 : ((price-priceCurrent)/price) * 100).toFixed(1) + '%'
        }
        return '';
    }, [data, priceCurrent]);

    const percentWidth = useMemo(() => {
        if (price && coins) {
            const width = ((price * coins)  * 100 / (type === 'spot' ? amountToFillSpot : amountToFillFutures));
            return (width >= 100 ? 100 : width);
        }
        return 0

    }, [price, coins, amountToFillSpot, amountToFillFutures, type]);

    const openDensity = useCallback(() => {
        dispatch({
            type: SET_DENSITY_INFO,
            payload: {
                symbol,
                type,
                side,
                percent,
                price,
                value,
                coins
            }
        });

        dispatch({
            type: SET_PRICE_LINE,
            payload: {
                type: `density`,
                variant: side,
                price,
                percent,
                value,
                color: side === ISide.bid ? '#019a23' : '#de2b2b'
            }
        })

    }, [percent, coins, price, symbol, type, value, side, dispatch]);

    useEffect(() => {
        if (selectedSymbolSideType) {
            dispatch({
                type: SET_DENSITY_INFO,
                payload: {
                    symbol,
                    type,
                    side,
                    percent,
                    price,
                    value,
                    coins
                }
            })

            dispatch({
                type: SET_PRICE_LINE,
                payload: {
                    type: `density`,
                    variant: side,
                    price,
                    percent,
                    value,
                    color: side === ISide.bid ? '#019a23' : '#de2b2b'
                }
            })
        }
    }, [selectedSymbolSideType, percent, coins, price, symbol, type, value, side, dispatch]);

    return (
        <td className={cx(classes.td, {[classes.selected]: selectedSymbolSideType})}>
            <div className={cx(classes.wrap)} onClick={openDensity}>
                <div className={cx(classes.bg, {[classes.green]: side === ISide.bid},
                  {[classes.red]: side === ISide.ask}) } style={{'width': `${percentWidth}%`}}>
                </div>
                {!!data.length &&
                  <div className={classes.tdContent}>
                      <span className={classes.value}>{value}</span>
                      <span>{percent}</span>
                      {
                          selectedSymbolSideType &&
                          <CaretRightOutlined style={{color: '#faad14'}} />
                      }
                  </div>
                }
            </div>
        </td>
    );
};

export default memo(Td);
