import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers';
import {composeWithDevTools} from "redux-devtools-extension";
import thunk from "redux-thunk";


const store = configureStore(
    {
        reducer: rootReducer,
        // @ts-ignore
        devTools: composeWithDevTools(),
        middleware: [thunk]
    });

export { store };