export const SET_PRICE_CURRENCY = 'SET_PRICE_CURRENCY';
export const SET_ORDER_BOOK_SPOT = 'SET_ORDER_BOOK_SPOT';
export const SET_ORDER_BOOK_FUTURES = 'SET_ORDER_BOOK_FUTURES';
export const SET_PRICE_LINE = 'SET_PRICE_LINE';
export const SET_PERCENT = 'SET_PERCENT';
export const SET_VOLUME_MIN_SPOT = 'SET_VOLUME_MIN_SPOT';
export const SET_VOLUME_MIN_FUTURES = 'SET_VOLUME_MIN_FUTURES';
export const SET_USER_DATA = 'SET_USER_DATA';
export const DELETE_USER_DATA = 'DELETE_USER_DATA';
export const SET_VOLUME_MIN_SPOT_FILL = 'SET_VOLUME_MIN_SPOT_FILL';
export const SET_VOLUME_MIN_FUTURES_FILL = 'SET_VOLUME_MIN_FUTURES_FILL';
export const SET_CHECK_AUTH_USER = 'SET_CHECK_AUTH_USER';
export const SET_CHECK_CREATED_USER_DOC = 'SET_CHECK_CREATED_USER_DOC';
export const SET_SETTINGS = 'SET_SETTINGS';
