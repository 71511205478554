export const reduceNumber = (number: number, decPlaces: number) => {
    if (!number) return 0
    let result = '';
    decPlaces = Math.pow(10,decPlaces);
    const abbrev = [ "K", "M", "B", "T" ];
    for (let i = abbrev.length-1; i >= 0; i--) {
        const size = Math.pow(10,(i+1)*3);
        if(size <= number) {
            number = Math.round(number * decPlaces / size) / decPlaces;
            if((number === 1000) && (i < abbrev.length - 1)) {
                number = 1;
                i++;
            }
            result += abbrev[i];
            break;
        }
    }
    return number + result;
}

export const formatNumber = (value?: any) => {
    if (isNaN(value)) return 0
    value += '';
    const list = value.split('.');
    const prefix = list[0].charAt(0) === '-' ? '-' : '';
    let num = prefix ? list[0].slice(1) : list[0];
    let result = '';
    while (num.length > 3) {
        result = ` ${num.slice(-3)}${result}`;
        num = num.slice(0, num.length - 3);
    }
    if (num) {
        result = num + result;
    }
    return `${prefix}${result}${list[1] ? `.${list[1]}` : ''}`;
}

export const maxValueFromArray = (list: (number)[][]) => {
    return Math.max(...list.map((item: (number)[]) => item[1]))
}

export const maxValueFromArr = (list: (number)[][]) => {
    if (list.length) {
        const max = Math.max(...list.map((item: number[]) => item[1]));
        return list.filter(item => item[1] === max)[0]
    }
    return []
}