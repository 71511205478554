import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import {store} from "./store";
import Router from './router';

function App() {
  return (
      <ReduxProvider store={store}>
        <div className="App">
            <Router/>
        </div>
      </ReduxProvider>
  );
}

export default App;
