import {useCallback} from "react";

export const wssStream = 'wss://stream.binance.com:9443/ws';
const wssFStream = 'wss://fstream.binance.com/stream'

const useWebSocket = () => {

    const createWebSocket = useCallback((wss: string, url: string) => {
        return new WebSocket(`${wss}${url}`);
    }, []);

    // Get price spot
    const streamTrade = useCallback((symbols: string) => {
        return createWebSocket(wssStream,symbols);
    }, [createWebSocket]);
    // Get order book for spot
    const streamDepth = useCallback((symbols: string) => {
        return createWebSocket(wssStream,symbols);
    }, [createWebSocket]);
    // Get order book futures
    const streamDepthFutures = useCallback((symbols: string) => {
        return createWebSocket(wssFStream,symbols);
    },[createWebSocket]);

    // Get chart
    const streamKline = useCallback((symbol: string, interval: string) => {
        return createWebSocket(wssStream, `/${symbol}@kline_${interval}`)
    }, [createWebSocket]);


    return {
        streamTrade,
        streamDepth,
        streamDepthFutures,
        streamKline,
        createWebSocket
    }

}

export default useWebSocket;
