export const languages = [
    {
        value: 'en',
        title: 'EN'
    },
    {
        value: 'uk',
        title: 'UA'
    },
    {
        value: 'ru',
        title: 'RU'
    },
]
