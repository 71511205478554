import React, {useEffect} from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Screener from "../pages/Screener";
import Header from "../components/Header";
import Main from "../pages/Main";
import {onAuthStateChanged} from "firebase/auth";
import {authFireBase} from "../firebase";
import {SET_CHECK_AUTH_USER, SET_USER_DATA} from "../store/actionTypes";
import {useDispatch, useSelector} from "react-redux";
import BigSpinner from "../components/BigSpinner";
import LogIn from "../pages/auth/LogIn";
import Register from "../pages/auth/Register";

const Router = () => {

    // @ts-ignore
    const authorizedCheck = useSelector(state => state.app.checkAuth);
    const dispatch = useDispatch();

    useEffect(() => {
        onAuthStateChanged(authFireBase, (user: any) => {
            if (user) {
                dispatch({
                    type: SET_USER_DATA,
                    payload: user
                });
            }
            dispatch({
                type: SET_CHECK_AUTH_USER
            })

        })
    }, [dispatch])

    return (
        <BrowserRouter >
            {
                authorizedCheck ? <>
                    <Header/>
                    <Routes>
                        <Route element={<Main/>} path="/"/>
                        <Route element={<LogIn/> } path="/login"/>
                        <Route element={<Register/>} path="/register" />
                        <Route element={<Screener/>} path="/old"/>
                    </Routes>
                </> : <BigSpinner/>
            }


        </BrowserRouter>
    )
}

export default Router;
