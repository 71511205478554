import {SET_PRICE_CURRENCY} from "../actionTypes";

const defaultState = {}

// @ts-ignore
export const priceReducer = ( state = defaultState, action) => {
    switch (action.type) {
        case SET_PRICE_CURRENCY:
            return {...state, [action.payload.symbol]: action.payload.price}
        default:
            return state
    }
}
