import React from 'react';
import classes from './SideBar.module.scss';
import CommonFilterContent from "../CommonFilter/CommonFilterContent";

const SideBar = () => {
    return (
        <div className={classes.sidebar}>
            <CommonFilterContent/>
        </div>
    );
};

export default SideBar;
