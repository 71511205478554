import React from 'react';
import classes from './Density.module.scss';
import {LineChartOutlined} from "@ant-design/icons";

const DensityEmpty = () => {



    return (
        <div className={classes.empty}>
            <div className="text-center">
                <LineChartOutlined style={{fontSize: '50px'}} />
                <br/>
                <br/>
                Select some Density
            </div>
        </div>
    );
};

export default DensityEmpty;
