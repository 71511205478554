import {createRequestClient} from "../request";

const baseURL = 'https://api.binance.com/api/v3';
const baseURLF = 'https://fapi.binance.com/fapi/v1';

const client = createRequestClient(baseURL);
const clientF = createRequestClient(baseURLF);

export const getOrderBookSpot = async (symbol: string, limit: number = 5000) => {
    return client({
        url: `/depth?symbol=${symbol}&limit=${limit}`,
        method: 'get',
    })
}
export const getOrderBookF = async (symbol: string, limit: number = 1000) => {
    return clientF({
        url: `depth?symbol=${symbol}&limit=${limit}`,
        method: 'get',
    })
}

export const getKline = async (symbol: string, interval: string, limit: number = 1000) => {
    return client({
        url: `klines?symbol=${symbol}&interval=${interval}&limit=${limit}`
    })
}