import {SET_CHECK_AUTH_USER, SET_CHECK_CREATED_USER_DOC} from "../actionTypes";

const defaultState = {
    checkAuth: false,
    checkCreatedUserDoc: true
}

// @ts-ignore
export const appReduce = (state = defaultState, action) => {
    switch (action.type) {
        case SET_CHECK_AUTH_USER:
            return {...state, checkAuth: true}
        case SET_CHECK_CREATED_USER_DOC:
            return {...state, checkCreatedUserDoc: action.payload}
        default:
            return state;
    }
}
